<template>
    <div class="c-p-page pc">
        <div class="c-p-page-content">
            <h1>{{$t('companyProfile.title')}}</h1>
            <article>
                <p>{{$t('companyProfile.p1')}}</p>
                <img src="@/assets/images/company/u670.jpg"/>
                <p>{{$t('companyProfile.p2')}}</p>
                <p>{{$t('companyProfile.p3')}}</p>
                <p>{{$t('companyProfile.p4')}}</p>
            </article>
        </div>
    </div>

</template>



<script>
    export default {
      data() {
        return {};
      }
    }
</script>




<style lang="scss">
    .c-p-page {
        background-color: var(--white);
    }

    .c-p-page-content {
        width: 100%;
        max-width: 1128px;
        margin: 0 auto;
        padding-top: 50px;

        @media screen and (max-width: 768px) {
            padding-top: 20px;
        }

        h1 {
            height: 100px;
            margin: 0;
            line-height: 100px;
            font-size: 70px;
            font-weight: 700;
            text-align: center;
            color: var(--black);

            @media screen and (max-width: 768px) {
                font-size: 24px;
                height: 60px;
                line-height: 60px;
            }

            @media screen and (max-width: 400px) {
                font-size: 24px;
                height: 45px;
                line-height: 45px;
            }
        }

        article {
            margin-top: 40px;
            padding-bottom: 100px;
            font-size: 16px;
            line-height: 24px;
            color: var(--black);

            @media screen and (max-width: 768px) {
                margin-top: 0px;
                padding: 0 15px 30px 15px;
            }

            p {
                text-indent: 2em;
                font-weight: 400;
            }
            p:first-child {
                font-weight: bold;
            }

            img {
                width: 50%;
                max-width: 540px;
                margin-right: 20px;
                float: left;
                clear: both;
            }
        }
    }
</style>
